import { Fragment } from "react";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import Layout from "./Layout";
import { useLocation } from "react-router";
import Login from "./components/login/Login";

function App() {
  const location = useLocation();
 
  if (sessionStorage.getItem("userName") == null) {
    return <Login />;
  } else {
    return (
      <Fragment>
        <div className="wrapper">
          <Navbar />
          <Sidebar />
          <Layout />
          <Footer />
        </div>
      </Fragment>
    );
  }
}

export default App;
