import React, { Fragment, useState } from "react";
import api from "../../api";
import { useEffect } from "react";
import Button from "react-bootstrap/Button";
import { Badge, Image } from "react-bootstrap";
import EnterStock from "./EnterStock";
import { Tooltip } from "react-tooltip";
import TextTruncate from "react-text-truncate"; // recommend
import "react-tooltip/dist/react-tooltip.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
function ListStock(props) {
  const [stock, setStock] = useState([]);
  const [show, setShow] = useState(false);
  const [idProd, setIdProd] = useState();
  const [globalFilter, setGlobalFilter] = useState(null);
  const handleOutStock = () => { };
  const handleEnterStock = () => { };

  const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  const handleEditStock = (id) => {
    setShow(true);
    setIdProd(id);
  };
  const fetchStock = () => {
    api.get(`Stocks/`).then((res) => {
      const stocks = res.data.result.stocks;
      console.log(stocks);
      setStock(stocks);
    });
  };
  //
  useEffect(() => {
    fetchStock();
  }, []);

  //
  const handleSave = () => {
    api.post();
  };


  const imageBodyTemplate = (rowData) => {
    return <img src={`http://medias.e-triangle.com/${rowData.product.prod_image}`} alt={rowData.product.prod_image} className="shadow-2 border-round" style={{ width: '64px' }} />;
  };
  const header = (
    <div className="d-flex flex-wrap gap-2 align-items-center justify-content-between">
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Recherche..." />
      </span>
    </div>
  );
  return (
    <Fragment>
      <DataTable value={stock} paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ minWidth: '50rem' }}
      globalFilter={globalFilter} header={header}>
        <Column field="image" header="" body={imageBodyTemplate}></Column>
        <Column field="product.prod_name" header="Designation" sortable tableStyle={{ minWidth: '50rem' }}></Column>
        <Column field="product.sub_category.sub_cat_title" header="Categorie" sortable></Column>
        <Column field="qte" header="Stock réél" sortable></Column>
        <Column field="qte" header="Stock virtuel" sortable></Column>
      </DataTable>



      {/* {stock.map((stocks) => (
        <tr>
          <td>
            <Image
              style={{ width: "50px" }}
              src={"https://medias.e-triangle.com/" + stocks.product.prod_image}
              thumbnail
              className="mr-1"
            />
            <TextTruncate
              line={1}
              element="span"
              truncateText="…"
              text={stocks.product.prod_name}
            />
          </td>
          <td>
            <Badge bg="secondary">
              {stocks.product.sub_category.sub_cat_title}
            </Badge>
          </td>
          <td className="text-center">{stocks.qte}</td>
          <td className="text-center">{stocks.qte}</td>
          <td className="d-flex justify-content-between">
            <Button
              title="Sortie de stock"
              variant="light"
              size="sm"
              onClick={() => handleEditStock(stocks.product_id)}
            >
              <i class="fas fa-arrow-up text-danger"></i>
            </Button>
            <Button
              title="Entrée de stock"
              variant="light"
              size="sm"
              onClick={() => handleEditStock(stocks.product_id)}
            >
              <i class="fas fa-arrow-down text-success"></i>
            </Button>
          </td>
        </tr>
      ))}
      <EnterStock handleClose={handleClose} show={show} idProd={idProd} /> */}
    </Fragment>
  );
}

export default ListStock;
