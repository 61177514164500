import React, { Fragment, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import SellerList from "./SellerList";
import "./styles.scss";
function Seller() {
  return (
    <Fragment>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Intermediaires</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to={"/sellers"}>Intermediaire</Link>
                </li>
                <li className="breadcrumb-item active">Liste intermediaire</li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <div className="card">
        <div className="card-body p-0">
          <div class="table-responsive scrollbar mx-n1 px-1">
            <SellerList />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Seller;
