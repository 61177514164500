import React, { Fragment, useEffect, useState, useRef } from "react";
import api from "../../api";
import { Link, Navigate } from "react-router-dom";
import DeleteProduct from "./DeleteProduct";
import EditProduct from "./EditProduct";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';

// 

function ProductList() {
  const [product, SetProduct] = useState([]);
  //
  const [prodId, setProdId] = useState();
  const [editMode, setEditMode] = useState(false);
  const [deleteMode, setDeleteMode] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const fetchProduct = () => {
    api.get(`Products/catalogue`).then((res) => {
      const products = res.data.result.products;
      console.log(products);
      SetProduct(products);
      if (products) {
        const timeout = setTimeout(() => {
          SetProduct(products);
          // setPending(false);
        }, 1000);
        return () => clearTimeout(timeout);
      } else {
        // setPending(false);
      }
    });
  };
  //
  const handleEdit = (id) => {
    setShow(true);
    setProdId(id);
    setEditMode(true);
    setDeleteMode(false);
  };

  const handleDelete = (id) => {
    setShow(true);
    setProdId(id);
    setDeleteMode(true);
    setEditMode(false);
  };
  //
  useEffect(() => {
    fetchProduct();
  }, []);


  // 



  const [globalFilter, setGlobalFilter] = useState(null);
  const [redirect, setRedirect] = useState(false);
  // 

  const toast = useRef(null);
  const dt = useRef(null);
  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const openNew = () => {
    setRedirect(true)
    if (redirect) {
      return <Navigate to="/addProduct" />
    }
  };


  const formatCurrency = (value) => {
    return value.toLocaleString('XOF', { style: 'currency', currency: 'XOF' });
  };

  const leftToolbarTemplate = () => {
    return (
      <div className="flex flex-wrap gap-2">
        <Link to={'/addProduct'} className="p-button p-component p-button-success font-weight-bold"><i className="pi pi-plus"></i>{" "} Nouveau produit</Link>
      </div>
    );
  };

  const rightToolbarTemplate = () => {
    return <Button label="Exporter" icon="pi pi-upload" severity="info" onClick={exportCSV} />;
  };

  const priceBodyTemplate = (rowData) => {
    return formatCurrency(rowData.prod_pu);
  };


  const statusBodyTemplate = (rowData) => {
    return <Tag value={rowData.statut_prod ? "Activé" : "Desactivé"} severity={getSeverity(rowData)}></Tag>;
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <Fragment>
        <Button icon="pi pi-pencil" rounded outlined className="mr-1" onClick={() => handleEdit(rowData.id)} />
        <Button icon="pi pi-trash"  disabled="disabled" rounded outlined severity="danger" onClick={() => handleDelete(rowData)} />
      </Fragment>
    );
  };

  const getSeverity = (rowData) => {
    if (rowData.statut_prod == 1) {
      return 'success';
    }
    else if (rowData.statut_prod == 0) {
      return 'danger';
    }
  };


  const imageBodyTemplate = (rowData) => {
    return <img src={`http://medias.e-triangle.com/${rowData.prod_image}`} alt={rowData.prod_image} className="shadow-2 border-round" style={{ width: '64px' }} />;
  };
  const header = (
    <div className="d-flex flex-wrap gap-2 align-items-center justify-content-between">
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Recherche..." />
      </span>
    </div>
  );

  return (
    <Fragment>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
        <DataTable ref={dt} value={product}
          dataKey="id" paginator rows={5} rowsPerPageOptions={[5, 10, 25]}
          globalFilter={globalFilter} header={header}>
          <Column field="image" header="" body={imageBodyTemplate}></Column>
          <Column field="prod_name" header="Designation" sortable style={{ minWidth: '16rem' }}></Column>
          <Column field="prod_pu" header="Prix" body={priceBodyTemplate} sortable style={{ minWidth: '8rem' }}></Column>
          <Column field="sub_category.sub_cat_title" header="Category" sortable style={{ minWidth: '10rem' }}></Column>
          <Column field="statut_prod" header="Status" body={statusBodyTemplate} sortable style={{ minWidth: '5rem' }}></Column>
          <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
        </DataTable>
      </div>

      {editMode && (
        <EditProduct
          show={show}
          onClose={handleClose}
          prodId={prodId}
          reFresh={fetchProduct}
        />
      )}
      {deleteMode && (
        <DeleteProduct
          show={show}
          onClose={handleClose}
          prodId={prodId}
          reFresh={fetchProduct}
        />
      )}


    </Fragment>
  );
}
export default ProductList;
