import React, { useEffect, useState ,useRef } from "react";
import { Fragment } from "react";
import api from "../../api";
import dateFormat, { masks } from "dateformat";
import EditSeller from "./EditSeller";
import DeleteSeller from "./DeleteSeller";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toolbar } from 'primereact/toolbar';
import { Link } from "react-router-dom";

function SellerList() {
  const [seller, SetSeller] = useState([]);
  const [sellerId, setSellerId] = useState();
  const [editMode, setEditMode] = useState(false);
  const [deleteMode, setDeleteMode] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const fetchSeller = () => {
    api.get(`Sellers/`).then((res) => {
      const sellers = res.data.result.sellers;
      console.log(sellers);
      SetSeller(sellers);

    });
  };
  //
  const handleEdit = (id) => {
    setShow(true);
    setSellerId(id);
    setEditMode(true);
    setDeleteMode(false);
  };
  const handleDelete = (id) => {
    setShow(true);
    setSellerId(id);
    setDeleteMode(true);
    setEditMode(false);
  };
  //
  useEffect(() => {
    fetchSeller();
  }, []);
  const dt = useRef(null);
  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const leftToolbarTemplate = () => {
    return (
      <div className="flex flex-wrap gap-2">
        <Link to={'/addSeller'} className="p-button p-component p-button-success font-weight-bold"><i className="pi pi-plus"></i>{" "} Ajouter</Link>
      </div>
    );
  };

  const rightToolbarTemplate = () => {
    return <Button label="Exporter" icon="pi pi-upload" severity="info" onClick={exportCSV} />;
  };

  const header = (
    <div className="d-flex flex-wrap gap-2 align-items-center justify-content-between">
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Recherche..." />
      </span>
    </div>
  );
  const bodyDateFormat = (rowData) => {
   return dateFormat(rowData.created, "dd/mm/yyyy");
  }
  const actionBodyTemplate = (rowData) => {
    return (
      <Fragment>
        <Button icon="pi pi-pencil" rounded outlined className="mr-2" onClick={() => handleEdit(rowData.id)} />
        <Button icon="pi pi-trash" rounded outlined severity="danger" onClick={() => handleDelete(rowData.id)} />
      </Fragment>
    );
  };

  return (
    <Fragment>
       <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
      <DataTable value={seller} paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]} globalFilter={globalFilter} header={header} tableStyle={{ minWidth: '50rem' }}>
        <Column field="first_name" sortable header="Nom"></Column>
        <Column field="last_name" sortable header="Prenoms" style={{ minWidth: '11rem' }}></Column>
        <Column field="email" filterField="email" sortable header="Email"></Column>
        <Column field="phone_no" sortable header="Téléphpone"></Column>
        <Column field="created" body={bodyDateFormat} sortable header="Date"></Column>
        <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '11rem' }}></Column>
      </DataTable>

      {/* 
      {seller.map((selleur) => (
        <tr>
       <td></td> 
          <td>{selleur.last_name + " " + selleur.first_name}</td>
          <td className="email align-middle white-space-nowrap pe-5">
            <a
              class="fw-semi-bold"
              href="mailto:contact@e-triangle.com"
              spellcheck="false"
            >
              {selleur.email}
            </a>
          </td>
          <td className="sort align-middle text-center">{selleur.phone_no}</td>
          <td>{dateFormat(selleur.created, "dd/mm/yyyy")}</td>
          <td>
            {" "}
            <Dropdown>
              <Dropdown.Toggle
                variant="light"
                id="dropdown-basic"
              ></Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => handleEdit(selleur.id)}>
                  <i class="far fa-edit text-success"></i> Modifier
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleDelete(selleur.id)}>
                  <i class="fas fa-trash text-danger"></i> Supprimer
                </Dropdown.Item>
                <Dropdown.Item href="#/action-3">
                  <i class="fas fa-bars text-info"></i> Detail
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </td>
        </tr>
      ))} */}
      {editMode && (
        <EditSeller
          show={show}
          onClose={handleClose}
          sellerId={sellerId}
          reFresh={fetchSeller}
        />
      )}
      {deleteMode && (
        <DeleteSeller
          show={show}
          onClose={handleClose}
          sellerId={sellerId}
          reFresh={fetchSeller}
        />
      )}
    </Fragment>
  );
}

export default SellerList;
