import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import ProductList from "./ProductList";
import { Button } from "react-bootstrap";

function Product(props) {

  return (
    <Fragment>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Catalogue produit</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to={'/product'}>Produit</Link>
                </li>
                <li className="breadcrumb-item active">Liste produit</li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      {/* <div className="card">
        <div className="card-header">
        <div className="searchInput">
            <input className="form-control" disabled type="search" name="" id=""  placeholder="Rechercher"/>
          </div>

          <div className="card-tools">
          <Button variant="light" disabled>
              <i class="fas fa-file-excel"></i> Exporter
            </Button> &nbsp;
          <Link to="/addProduct" className="btn btn-primary"><i class="fas fa-plus-circle"></i>  Ajouter produit </Link>
          </div>
        </div>
        <div className="card-body p-0"> */}
          {/* Start creating your amazing application! */}
           <ProductList/> 
            {/* <table class="table fs--1 mb-0">
              <thead>
                <tr>
                <th scope="col"></th>
                <th scope="col"></th>
                  <th scope="col">NOM PRODUIT</th>
                  <th scope="col">PRIX.U</th>
                  <th scope="col">CATEGORIES</th>
                  <th scope="col">VENTE</th>
                  <th scope="col">ACTION</th>
                </tr>
              </thead>
              <tbody>
           
              </tbody>
            </table> 
          </div>
          
        </div>
        <div className="card-footer">Footer</div>*/}
    </Fragment>
  );
}

export default Product;
