import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import dateFormat, { masks } from "dateformat";
import { i18n } from "dateformat";
import { Badge, Col, Container, Row } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";
import api from "../../api";
import { Link } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Tag } from "primereact/tag";

i18n.dayNames = [
  "Dim",
  "Lun",
  "Mar",
  "Mer",
  "Jeu",
  "Ven",
  "Sam",
  "Dimanche",
  "Lundi",
  "Mardi",
  "Mercredi",
  "Jeudi",
  "Vendredi",
  "Samedi",
];

i18n.monthNames = [
  "Jan",
  "Fev",
  "Mar",
  "Avr",
  "Mai",
  "Juin",
  "Juil",
  "Aout",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
  "Janvier",
  "Fevrier",
  "Mars",
  "Avril",
  "Mai",
  "Juin",
  "Juillet",
  "Août",
  "Septembre",
  "Octobre",
  "November",
  "Decembre",
];

function OrderList(props) {
  const [order, sertOrder] = useState([]);
  const [globalFilter, setGlobalFilter] = useState(null);
  const fetchOrder = () => {
    api.get(`Orders/`).then((res) => {
      const order = res.data.result.orders;
      console.log("MyOrder", order);
      sertOrder(order);
    });
  };

  //
  useEffect(() => {
    fetchOrder();
  }, []);
  const bodyDateFormat = (rowData) => {
    return dateFormat(rowData.creadted, "ddd dd mmm yyyy HH:MM:ss");
  }
  const header = (
    <div className="d-flex flex-wrap gap-2 align-items-center justify-content-between">
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Recherche..." />
      </span>
    </div>
  );

  const orderIdTemplate = (rowData) => {
    return (<Link className="text-red fontweight-bold"
      to={
        "/view-order?IdOrders=" +
        rowData.id +
        "&" +
        "IdSeller=" +
        rowData.seller.id
      }
    >
      {" "}
      #{rowData.id}
    </Link>)

  }
  const formatCurrency = (value) => {
    return value.toLocaleString('XOF', { style: 'currency', currency: 'XOF' });
  };
  const amountTemplate = (rowData) => {
    return formatCurrency((rowData.ttc_amount))
  }
  const statusBodyTemplate = (rowData) => {
    return <Tag value={rowData.statut_order.title} severity={rowData.statut_order.color}></Tag>;
  };

  const getSeverity = (rowData) => {
    if (rowData.statut_prod == 1) {
      return 'success';
    }
    else if (rowData.statut_prod == 0) {
      return 'danger';
    }
  };
  return (
    <Fragment>
      <DataTable value={order} tableStyle={{ minWidth: '50rem' }} globalFilter={globalFilter} header={header} paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]}>
        <Column field="seller.id" body={orderIdTemplate} sortable header="ID"></Column>
        <Column body={amountTemplate} sortable header="Montant"></Column>
        <Column field="seller.first_name" sortable header="Intermédiaire"></Column>
        <Column field="statut_order.title" body={statusBodyTemplate} sortable header="Statut"></Column>
        <Column field="creadted" body={bodyDateFormat} sortable header="Date"></Column>
      </DataTable>
    </Fragment>
  );
}

export default OrderList;
