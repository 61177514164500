import React, { Fragment } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Card } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { useState } from "react";
import api from "../../api";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
function AddSeller(props) {
  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNo: "",
    addr: "",
    body: "",
  });
  //
  const handleChange = (field, value) => {
    setForm((state) => ({ ...state, [field]: value }));
  };

  const handleSave = (e) => {
    e.preventDefault();
    const _form = {
      first_name: form.firstName,
      last_name: form.lastName,
      email: form.email,
      phone_no: form.phoneNo,
      adresse: form.addr,
      body: form.body,
    };
    try {
      api.post(`Sellers/AddSeller`, _form).then((res) => {
        if (res.data.status === "OK") {
          console.log("Seller Account  saved !");
          // reFresh();
          document.getElementById("resetForm").reset();
          Swal.fire({
            icon: "success",
            title: "Enregistré avec succès",
            text: "Nous avons envoyé les paramètres authentification à l'adresse email de l'intermadiaire",
          });
        }
      });
    } catch (e) {
      console.log("Seller Account not saved !");
    }
  };

  return (
    <Fragment>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 ><i class="fas fa-users text-danger" ></i> Ajouter intermediaire</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to={'/sellers'}>Intermediaire</Link>
                </li>
                <li className="breadcrumb-item active">
                
                  Ajouter Intermediaire
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section>
        <Container>
          <Row>
            
            <Col>
              <Form onSubmit={handleSave} id="resetForm">
                <Row>
                  <Col>
                      <Form.Group className="mb-3">
                        <Form.Label>Nom</Form.Label>
                        <Form.Control
                          type="text"
                          value={form.lastName}
                          required
                          onChange={(e) =>
                            handleChange("lastName", e.target.value)
                          }
                        />
                      </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>Prenoms</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        value={form.firstName}
                        onChange={(e) =>
                          handleChange("firstName", e.target.value)
                        }
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Téléphone</Form.Label>
                      <Form.Control
                        type="text"
                        value={form.phoneNo}
                        onChange={(e) =>
                          handleChange("phoneNo", e.target.value)
                        }
                      />
                    </Form.Group>
                  </Col>

                  <Col>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>
                        Email <small className="text-danger">(*)</small>
                      </Form.Label>
                      <Form.Control
                        type="email"
                        required
                        value={form.email}
                        onChange={(e) => handleChange("email", e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                {/* <Form.Group className="mb-3">
                  <Form.Label>Boutique</Form.Label>
                  <Form.Control
                    type="text"
                    value={form.shopName}
                    onChange={(e) => handleChange("shopName", e.target.value)}
                  />
                </Form.Group> */}

                <Form.Group className="mb-3">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Adresse</Form.Label>
                    <Form.Control
                      type="text"
                      value={form.addr}
                      onChange={(e) => handleChange("addr", e.target.value)}
                    />
                  </Form.Group>

                  <Form.Label>Observation</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={5}
                    value={form.body}
                    onChange={(e) => handleChange("body", e.target.value)}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Button variant="success" type="submit">
                    <i class="fas fa-save"></i> Valider
                  </Button>
                </Form.Group>
              </Form>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
}

export default AddSeller;
