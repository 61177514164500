import React, { Fragment } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { Button, Table } from "react-bootstrap";
import ListStock from "./ListStock";

function Stock(props) {
  return (
    <Fragment>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Stock</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="#">Stock</a>
                </li>
                <li className="breadcrumb-item active">Gestion stock</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section>
        <Container>
          <Row>
            <Col>
              <Card >

                <ListStock />
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
}

export default Stock;
