import React, { Fragment, useEffect, useState } from "react";
import { Badge, Col, Container, Image, Row, Table } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import api from "../../api";
import Swal from "sweetalert2";
import ListGroup from "react-bootstrap/ListGroup";
import TextTruncate from "react-text-truncate";
import dateFormat, { masks } from "dateformat";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import StatusSelecBox from "./StatusSelecBox";
const now = new Date();

function OrderView(props) {
  const [order, serOrder] = useState([]);
  const [statut, setStatut] = useState("");
  const [sumary, setSumary] = useState({
    articleAmount: "",
    shippingAmont: "",
    orderStatus: "",
    orderStatut: "",
    orderCustomers: "",
    custPhone: "",
    orderShipping: "",
    shippingDate: "",
    feesAmount: "",
  });
  const fetchOrderLine = () => {
    api.get(`LigneOrders/getOrderLineById/` + myLink()).then((res) => {
      const order = res.data.result.ligneOrders;
      console.log(order);
      serOrder(order);
      setSumary({
        articleAmount: res.data.result.ligneOrders[0].order.article_amount,
        feesAmount: res.data.result.ligneOrders[0].order.fees_amont,
        shippingAmont: res.data.result.ligneOrders[0].order.shipping_amount,
        orderStatus: res.data.result.ligneOrders[0].order.statut_order.title,
        orderStatut: res.data.result.ligneOrders[0].order.statut_order_id,
        orderCustomers: res.data.result.ligneOrders[0].order.customer.cust_name,
        //
        custPhone: res.data.result.ligneOrders[0].order.customer.cust_phone,
        orderShipping: res.data.result.ligneOrders[0].order.shippings[0].adress,
        shippingDate:
          res.data.result.ligneOrders[0].order.shippings[0].shipping_date,
      });
    });
  };
  useEffect(() => {
    fetchOrderLine();
  }, []);
  const myLink = () => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get("IdOrders");
  };

  // const handleChange = (field, value) => {
  //   setStatut((state) => ({ ...state, [field]: value }));
  // };
  const handleChange = (e) => {
    setStatut(e.target.value);
  };
  const handleSave = (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("statut", statut);
    //+ catId,

    api.post(`Orders/edit/` + myLink(), data).then((res) => {
      if (res.data.status === "OK") {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        // this.ErrorSwal();
      }
    });
  };
  return (
    <Fragment>
      <section>
        <Container>
          <Row>
            <Col >
              <h2>Commande N°{myLink()}</h2>
              {/* <h5>IntermediaireID:</h5> */}
              <p>
                {/* Statut de commande:{" "} */}
                <p className="font-weight-bold item-align-center">
                  <i class="fas fa-vote-yea"></i> {sumary.orderStatus}
                </p>
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container>
          <Row>
            <Col md={8}>
              <Card>
                <Card.Header className="bg-light">
                  Panier({order.length})
                </Card.Header>
                <Card.Body className="p-0">
                  <Table responsive="sm">
                    <thead>
                      <tr>
                        <th></th>
                        <th>Produit</th>
                        <th>Quantité</th>
                        <th>Prix</th>
                        <th>Fees</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {order.map((orders) => (
                        <tr>
                          <td>
                            <Image
                              style={{ width: "100px" }}
                              src={"https://medias.e-triangle.com/" + orders.product.prod_image}
                              thumbnail
                            />
                          </td>
                          <td>
                            <TextTruncate
                              line={2}
                              element="span"
                              truncateText="…"
                              text={orders.product.prod_name}
                            //textTruncateChild={<a href="#">Read on</a>}
                            />
                          </td>
                          <td className="text-center">{orders.qauntity}</td>
                          <td>{orders.pu}CFA</td>
                          <td>{orders.fees_amount}CFA</td>
                          <td>
                            {(orders.pu + orders.fees_amount) * orders.qauntity}
                            CFA
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
              <Row>
                <Col>
                  <Card>
                    <Card.Header> Details commande</Card.Header>
                    <Card.Body>
                      <ul className="navOrderView">
                        <li>
                          <b>Client: </b> {sumary.orderCustomers}
                        </li>
                        <li>
                          <b>Téléphone: </b> {sumary.custPhone}
                        </li>
                        <li>
                          <b>Adresse livraison: </b> {sumary.orderShipping}
                        </li>
                        <li>
                          <b>Date de livraison: </b>
                          {dateFormat(sumary.shippingDate, "dd/mm/yyyy")}
                        </li>
                        <li>
                          <b>Méthode de paiement: </b>Cash à la livraison
                        </li>
                      </ul>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col md={4}>
              <Card>
                <Card.Header className="bg-light">RESUME PANIER</Card.Header>
                <Card.Body className="p-0">
                  <ListGroup variant="flush" size="sm">
                    <ListGroup.Item className="d-flex justify-content-between">
                      <p>Sous Total </p>
                      <p>{sumary.articleAmount}CFA</p>
                    </ListGroup.Item>
                    <ListGroup.Item className="d-flex justify-content-between">
                      <p>Total Commission</p>
                      <p>{sumary.feesAmount}CFA</p>
                    </ListGroup.Item>
                    <ListGroup.Item className="d-flex justify-content-between">
                      <p>Livraison</p>
                      <p>{sumary.shippingAmont}CFA</p>
                    </ListGroup.Item>
                  </ListGroup>
                </Card.Body>
                <Card.Footer className="text-muted font-weight-bold">
                <div className="d-flex justify-content-between ">
                    <p>Montant Total :</p>
                    <p>{sumary.articleAmount + sumary.shippingAmont+sumary.feesAmount} F CFA</p>
                  </div>
                  <div className="d-flex justify-content-between ">
                    <p>Montant à recevoir :</p>
                    <p>{sumary.articleAmount + sumary.shippingAmont} F CFA</p>
                  </div>
                  <div className="d-flex justify-content-between ">
                    <p>Montant à Payé :</p>
                    <p>{sumary.feesAmount} F CFA</p>
                  </div>
                </Card.Footer>
              </Card>
              <Card>
                <Card.Header>Statut de commande</Card.Header>
                <Card.Body>
                  <Form onSubmit={handleSave}>
                    <Form.Select
                      className="form-control mb-3"
                      onChange={handleChange}
                    >
                      <StatusSelecBox />
                    </Form.Select>
                    <Button
                      disabled={
                        sumary.orderStatut === 4 ||
                          sumary.orderStatut === 2 ||
                          sumary.orderStatut === 3
                          ? true
                          : false
                      }
                      variant="primary"
                      type="submit"
                      className="btn-block btn-sm"
                    >
                      valider
                    </Button>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
}

export default OrderView;
